//update Reminder Payload
export interface UpdateReminderPayload {
  details: string;
  id: number | null;
  assigned_to: number | null;
  due_date: any;
  due_time: any;
  status_id: number | null;
}

export type StatusType = "AD" | "SD";

export const getStatusColor = (status: StatusType) => {
  switch (status) {
    case "AD":
      return "linear-gradient(180deg, #DC69AE 0%, #E95252 100%)";
    case "SD":
      return "linear-gradient(180deg, #B6DC69 0%, #57DB7A 100%)";
    default:
      return "black";
  }
};
