import React from "react";
import styled from "styled-components";
import "tippy.js/dist/tippy.css";
import { getStatusColor, StatusType } from "../../store/type";

interface StatusProps {
  status: StatusType;
  text?: string;
}

function StatusIndicator(props: StatusProps) {
  const statusColor = getStatusColor(props.status);

  return (
    <Container>
      <StatusCircleContainer status={props.status}>
        <StatusCircle color={statusColor} />
      </StatusCircleContainer>

      <Text status={props.status}>{props.text}</Text>
    </Container>
  );
}

const StatusCircleContainer = styled.span<{ status: StatusType }>`
  height: 20px;
  width: 20px;
  border: 1px solid
    ${(props) =>
      props.status === "SD"
        ? "#30fe43"
        : props.status === "AD"
        ? "#E95252"
        : "transparent"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusCircle = styled.span<{ color: string }>`
  height: 10px;
  width: 10px;
  background: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  transition: 0.2s ease all;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 22px;
`;

const Text = styled.span<{ status: StatusType }>`
  font-size: 13px;
  font-weight: normal;
  text-transform: capitalize;
  font-weight: 500;
  color: ${(props) =>
    props.status === "SD"
      ? "#57DB7A"
      : props.status === "AD"
      ? "#E95252"
      : "transparent"};
`;

export default StatusIndicator;
