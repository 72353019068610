import { createGlobalStyle } from "styled-components";

// Reset css use for rest default css of browser
export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing:border-box;
  
  }

  button {
    background: none;
		cursor: pointer;
    border: none;
  }

  :root {
    --primary-color: #23272E;
    --primary-background: #F3F4F8;
    --primary-font-color: #000;
    --shadow-black-color: rgba(0, 0, 0, 0.38);

  }

  body, html, #root {
    width: 100%;
    height: 100%;
    position: relative;
   
  }

  body {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 16px;
  }
legend {  
  width: auto;
  }
svg {
  vertical-align: baseline;
}  

  .flex{
    display:flex;
  }
  .flex-justify{
    justify-content: space-between;
    display: flex;
  }
  .flex-center{
    display: flex;
    align-items:center;
  }

  
`;
