import React from "react";
import styled from "styled-components";

interface Props {
  className?: any;
}

const StyledIconDebrief = styled.svg`
  & .path {
    fill: #3a3630;
    opacity: 0.4;
    transition: opacity 0.3s ease;
  }

  // &:hover .path {
  //   opacity: 1;
  // }
`;

export const Debrief = ({ className }: Props): JSX.Element => {
  return (
    <StyledIconDebrief
      className={`icon-debrief-1 ${className}`}
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M9.05008 0.499512C8.86007 0.499512 8.67005 0.594516 8.48004 0.689521L0.974543 4.86972C0.689524 5.05973 0.499512 5.34474 0.499512 5.72476V14.2752C0.499512 14.6552 0.689524 14.9402 0.974543 15.1302L8.48004 19.3104C8.67005 19.4054 8.86007 19.5004 9.05008 19.5004C9.24009 19.5004 9.43011 19.4054 9.62012 19.3104L10.4752 18.8354C10.1902 18.2653 10.0951 17.6003 10.0001 16.9353V10.57L15.7005 7.33983V10.95C16.3656 10.95 17.0306 11.045 17.6006 11.235V5.72476C17.6006 5.34474 17.4106 5.05973 17.1256 4.86972L9.62012 0.689521C9.43011 0.594516 9.24009 0.499512 9.05008 0.499512ZM9.05008 2.58961L14.7505 5.72476L12.8503 6.76981L7.24496 3.53965L9.05008 2.58961ZM5.34483 4.5847L10.9502 7.90986L9.05008 8.95491L3.3497 5.72476L5.34483 4.5847ZM2.39964 7.33983L8.10002 10.57V16.9353L2.39964 13.7051V7.33983ZM17.8857 13.6101L14.4654 17.0303L12.9453 15.5102L11.9003 16.6503L14.5604 19.5004L19.1208 14.9402L17.8857 13.6101Z"
        fill="#aea9a1"
        fillRule="evenodd"
      />
    </StyledIconDebrief>
  );
};
