import Cookies from "js-cookie";

export const getUrlParams = (input: string) => {
  const params = new URLSearchParams(input);
  return params;
};

export const getCookie = (name: string) => {
  const cookieValue = Cookies.get(name);
  return cookieValue;
};

export const setCookie = (name: string, value: string) => {
  Cookies.set(name, value, { secure: true, sameSite: "strict" });
};

export const removeCookie = (name: string) => {
  Cookies.remove(name);
};
