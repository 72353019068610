import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format, isValid, parse } from "date-fns";
import {
  ContentsContainer,
  DatePickerWrap,
  DateTimeContainer,
  DetailedContainer,
  InnerContainer,
  Label,
  Left,
  MiniContainer,
  ReferenceContainer,
  Right,
  Row,
  StyledDateContainer,
  StyledText,
  StyledTime,
  TimeWrapper,
} from "./Reminder-styles";
import {
  useIsModalOpen,
  useReminderId,
  useSingleReminderData,
  useUpdateReminderData,
} from "../../store/store";
import Loader from "../../common/loader/Loader";
import AssigneeDropdown from "./AsigneeDropodwn";
import useUpdateReminder from "./useUpdateReminder";
import StatusIndicator from "./StatusIndicator";
import {
  OrderActionModal,
  useApiHook,
} from "@bjsdistribution/dms-component-library";
import { DatePicker } from "antd";
import dayjs from "dayjs";

interface ApiResponse {
  message: string;
  code: number;
  error: string | null;
  error_traceback: string | null;
  data: [];
}

const ViewReminderModal = () => {
  const updateReminder = useUpdateReminder();
  const [, setDueDate] = useState<any | null>(null);
  const [reminderData, setData] = useSingleReminderData();
  const [reminderPayload, setPayload] = useUpdateReminderData();
  const [, setIsModalOpen] = useIsModalOpen();
  const [reminderId, setUseRefId] = useReminderId();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //GET Reminders for particular id
  const { data: fetchedData, loading: loading } = useApiHook<{
    data: ApiResponse[];
  }>({
    endpoint: `/tasks/reminder/${reminderId}`,
    method: "GET",
  });

  useEffect(() => {
    if (fetchedData && fetchedData.data) {
      setData(fetchedData.data[0]);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (reminderData) {
      setPayload({
        ...reminderPayload,
        details: reminderData?.details,
        id: reminderData?.id,
        assigned_to: reminderData?.assigned_to,
        due_date: reminderData?.due_date,
        due_time: reminderData?.due_time,
        status_id: reminderData?.status_id,
      });
      setDueDate(reminderData?.due_date);
    }
  }, [reminderData]);

  const handleDateChange = (date: any | null) => {
    if (date && isValid(date.$d)) {
      setDueDate(date);
      const formattedDate = format(new Date(date.$d), "yyyy-MM-dd");

      setPayload((prevState: any) => ({
        ...prevState,
        due_date: formattedDate,
      }));
    } else {
      setDueDate(null);
      setPayload((prevState: any) => ({
        ...prevState,
        due_date: undefined,
      }));
    }
  };

  const handleTime = (event: any) => {
    setPayload({
      ...reminderPayload,
      due_time: event.target.value,
    });
  };

  const onChangeDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPayload({
      ...reminderPayload,
      details: event.target.value,
    });
  };

  //set mark as complete
  const handleResolved = (event: any) => {
    setPayload({
      ...reminderPayload,
      status_id: reminderPayload.status_id === event ? 0 : event,
    });
  };

  const handleEditReminder = async () => {
    // setLoading(true);
    await updateReminder([reminderPayload]);
    // fetchRemindersData();
    closeModal();
  };

  const isDisabled = reminderData?.reminder_status?.name_alias === "resolved";

  const additional =
    reminderData?.order_data?.order_recipient?.recipient_name +
    " " +
    reminderData?.order_data?.order_recipient?.postcode;

  return (
    <>
      {reminderData && loading && <Loader />}
      <OrderActionModal
        headerTitle="View Reminder"
        onCloseHeader={closeModal}
        onSubmit={handleEditReminder}
        submitBtnTitle="Save Details"
        isSubmitDisabled={isDisabled}
        showAdditionalInfo
        infoText={additional || "NA"}
        headerActions={
          <OuterDiv>
            {reminderData?.reminder_status?.name_alias === "pending" ||
            reminderData?.reminder_status?.name_alias === "unresolved" ? (
              <>
                <StyledWrapper>
                  <StyledLabel>
                    <StyledCheckbox>
                      <input
                        type="checkbox"
                        onChange={() => handleResolved(2)}
                      />
                      <RoundCheckbox />
                    </StyledCheckbox>
                    <StyledSpan>Mark As Complete</StyledSpan>
                  </StyledLabel>
                </StyledWrapper>
              </>
            ) : null}
          </OuterDiv>
        }
      >
        <ReferenceContainer>
          <InnerContainer>
            <MiniContainer>
              {" "}
              <Label>Reference {} </Label>
              <LabelText>{reminderData?.reference || "N/A"}</LabelText>
            </MiniContainer>
            <MiniContainer>
              {" "}
              <Label>Status </Label>
              <LabelText>
                {reminderData?.reminder_status?.name_alias === "pending" ||
                reminderData?.reminder_status?.name_alias === "unresolved" ? (
                  <StatusIndicator text="Incomplete" status="AD" />
                ) : (
                  <StatusIndicator
                    text={`Completed on ${reminderData?.completed} by ${reminderData?.completed_by}`}
                    status="SD"
                  />
                )}
              </LabelText>
            </MiniContainer>
          </InnerContainer>
        </ReferenceContainer>
        <ContentsContainer>
          <Left>
            <AssigneeDropdown />
            <DateTimeContainer>
              <Label>Due </Label>
              <Row>
                <StyleContainer>
                  <DatePickerWrap>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={
                        reminderPayload?.due_date
                          ? dayjs(reminderPayload?.due_date)
                          : null
                      }
                      onChange={handleDateChange}
                    />
                  </DatePickerWrap>
                </StyleContainer>

                <TimeWrapper>
                  <StyledTime
                    onChange={handleTime}
                    value={reminderPayload?.due_time}
                    type="time"
                  />
                </TimeWrapper>
              </Row>
            </DateTimeContainer>
          </Left>
          <Right>
            <DetailedContainer>
              <Label>Details</Label>
              <StyledText
                name="text"
                required
                value={reminderPayload?.details}
                onChange={onChangeDetails}
              />
            </DetailedContainer>
          </Right>
        </ContentsContainer>
      </OrderActionModal>
    </>
  );
};

const StyleContainer = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 235px;
`;

const StyledWrapper = styled.div`
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 5px;
  border-radius: 30px;
  background: #fff;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
`;

const StyledSpan = styled.span`
  color: #131313;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
`;

const StyledCheckbox = styled.div`
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + span:before {
    width: 14px;
    height: 14px;
    border: 1.5px solid #3a3630;
    content: "";
    display: inline-block;
    margin: 0 8px 0 0;
    padding: 0;
    border-radius: 50%;
  }

  input[type="checkbox"]:checked + span:before {
    background: #feba30;
    border: 1.5px solid #3a3630;
    box-shadow: inset 0px 0px 0px 1px #fff;
    color: #333;
    content: "";
    text-align: center;
    border-radius: 50%;
    display: flex;
    width: 14px;
    height: 14px;
  }

  input[type="checkbox"]:focus + span::before {
    outline: 0;
  }
`;

const OuterDiv = styled.div`
  padding-left: 33%;
`;

const RoundCheckbox = styled.span``;

const LabelText = styled.span`
  color: var(--primary-dark-brown, #3a3630);
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
export default ViewReminderModal;
