import styled from "styled-components";

export const StyledLink = styled.a`
  color: #6396e5;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
`;

export const CustomizedRadio = styled.div`
  .custom-wrapper {
    background: none;
    border: none;
    gap: 7px;
    height: 0;
  }
  .appearance {
    appearance: auto;
  }
`;

export const MainDiv = styled.div`
  display: flex;
  gap: 12px;
  border-radius: 0 20px 20px 0;
  float: right;
  border-left: 4px solid #dc69ae;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  top: 500px;
  right: 65px;
  opacity: 1;
  overflow: unset;
  color: inherit;
  pointer-events: auto;
  position: fixed;
  transform: translate3d(57px, 308px, 0px) scaleX(1);
  z-index: 9;
  box-shadow: rgb(171 158 158 / 35%) 0px 5px 15px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;

  .subheading {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #6a7378;
    gap: 10px;
    display: flex;
  }
`;

export const Top = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-left: 10px;
  }
`;

export const Border = styled.div`
  width: 10px;
  border-radius: 20px 0px 0px 20px;
  background: #e95252;
`;

export const Left = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;

  .heading {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #e95252;
    display: flex;
    gap: 10px;
    .circleRem {
      // background-color: #dc69ae;
      // height: 25px;
      // width: 25px;
      // border-radius: 50%;
      color: #6396e5;
      justify-content: center;
      align-items: center;
      // /* text-align: unset; */
      display: flex;
      font-size: 14px;
    }
  }

  .subheading {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #e95252;
    gap: 10px;
    display: flex;
  }
`;

export const Right = styled.div`
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 6px 15px 6px 0px;
`;

export const Container = styled.div`
  display: flex;
  gap: 12px;
  background: #ffebf7;
  border-radius: 15px;
`;
