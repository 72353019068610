import React from "react";

const NotificationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M15.5231 6.90112C15.5231 5.20343 14.8909 3.57529 13.7654 2.37485C12.64 1.1744 11.1137 0.5 9.52208 0.5C7.9305 0.5 6.40411 1.1744 5.2787 2.37485C4.15328 3.57529 3.52103 5.20343 3.52103 6.90112C3.52103 14.3691 0.520508 16.5028 0.520508 16.5028H18.5236C18.5236 16.5028 15.5231 14.3691 15.5231 6.90112Z"
        stroke="#858585"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2526 19.5034C11.0768 19.8066 10.8244 20.0582 10.5207 20.233C10.217 20.408 9.87275 20.5001 9.52229 20.5001C9.17183 20.5001 8.82757 20.408 8.52392 20.233C8.22027 20.0582 7.96782 19.8066 7.79199 19.5034"
        stroke="#858585"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotificationIcon;
