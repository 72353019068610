import { getBaseEndpoint } from "@bjsdistribution/dms-component-library";
import { getCookie } from "../../common/utils";
import { useReminderLoading } from "../../store/store";
import { UpdateReminderPayload } from "../../store/type";

function useUpdateReminder() {
  const ssoToken = getCookie("id_token");
  const [, setLoading] = useReminderLoading();

  const update = async (data: UpdateReminderPayload[]) => {
    const endpoint = `${getBaseEndpoint()}/tasks/reminder/update/`;
    setLoading(true);

    try {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ssoToken}`,
        },
        method: "PUT",
        body: JSON.stringify(data),
      };

      const response = await fetch(endpoint, requestOptions);

      if (!response.ok) {
        throw new Error("response is not ok");
      }

      const json = await response.json();
      alert("Reminder has been updated");
      return json;
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  return update;
}

export default useUpdateReminder;
