// App.js
import React from 'react';
import AppRouter from './AppRouter';
import { GlobalStyles } from './style/global';



function App() {
  console.log('project env',process.env.REACT_APP_API_PATH);
  
  return (
    <>
     <GlobalStyles />
     <AppRouter />
    </>
     
  );
}

export default App;
