import { StyledAside } from "./AsideStyle";
import { menuData } from "./menu";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { Tooltip } from 'antd'; 
import { Collapse } from "../../assets/icons/Collapse";
// import { FiChevronLeft, FiChevronRight } from "react-icons/fi"; // Import icons for collapse/expand

const Aside = ({toggleSidebar}:any) => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<number | null>(2); // Use number or null for openMenu state


  // const handleMenuClick = (id: number) => {
  //   setOpenMenu(openMenu === id ? null : id);
  // };

  
  console.log( location.pathname);
  

  return ( 
    <StyledAside >
      <div className="navigation">
        <nav style={{ position: "relative", borderRight: "1px solid #ccc" }}>
          <ul className="main-menu">
            {menuData.map((item, id) => (

                 <Tooltip title={item.name} placement="right">
              <li onClick={()=>toggleSidebar()}
                key={id}
                className={
                  location.pathname.startsWith(item.url) ? "active" : ""  
                }
              >
              
                <NavLink  
                  to={item.url} 
                  className={({ isActive }) => (isActive ? "active" : "")}
                 
                >
                   

                  <div className="flex flex-item-center gap10">
                    <span>{item.icon}</span>
                  </div>
                
                </NavLink>
                {(
                  <div className="submenu">
                    <h2>{item.name}</h2>
                    <ul>
                      
                      {item?.subMenu?.map((suMenu, index) => (
                        <li key={index} onClick={()=>toggleSidebar()}>
                          <NavLink
                            end
                            to={suMenu.url}
                            className={({ isActive }) => (isActive ? "active" : "")}
                          >
                            <div className="flex flex-item-center gap10">
                              <span>{suMenu.name}</span>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                    <div className="toggle-icon" onClick={()=>toggleSidebar()}>
            <Collapse/>
            </div>
                  </div>
                )}
                  
              </li>
              </Tooltip>
            ))}
           
          </ul>
        </nav>
      </div>
    </StyledAside>
  );
};

export default Aside;
