import React from "react";

function ReminderIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.551 7.0565C21.3855 6.767 21.209 6.485 21.019 6.2125C21.604 5.8635 22 5.231 22 4.5C22 3.3955 21.1045 2.5 20 2.5C19.269 2.5 18.6365 2.896 18.2875 3.4815C18.015 3.291 17.7335 3.115 17.4435 2.9495C17.9695 2.0835 18.913 1.5 20 1.5C21.6565 1.5 23 2.8435 23 4.5C23 5.587 22.416 6.5305 21.551 7.0565ZM22 12.5C22 15.0855 21.0105 17.434 19.4 19.2095L21.8575 21.667C22.048 21.8575 22.048 22.1665 21.8575 22.3575C21.6665 22.548 21.3575 22.548 21.167 22.3575L18.7095 19.9C16.934 21.5105 14.5855 22.5 12 22.5C9.4145 22.5 7.066 21.5105 5.291 19.9L2.833 22.3575C2.6425 22.548 2.333 22.548 2.1425 22.3575C1.952 22.1665 1.952 21.8575 2.1425 21.667L4.6 19.2095C2.9895 17.434 2 15.0855 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5ZM12 3.5C7.0295 3.5 3 7.5295 3 12.5C3 17.4705 7.0295 21.5 12 21.5C16.9705 21.5 21 17.4705 21 12.5C21 7.5295 16.9705 3.5 12 3.5ZM12.5 14.429V19C12.5 19.2765 12.2765 19.5 12 19.5C11.7235 19.5 11.5 19.2765 11.5 19V14.429C10.6385 14.206 10 13.4305 10 12.5C10 12.1255 10.1095 11.7795 10.289 11.4795L8.1425 9.3335C7.952 9.143 7.952 8.8335 8.1425 8.643C8.3335 8.4525 8.6425 8.4525 8.833 8.643L10.9795 10.789C11.2795 10.6095 11.6255 10.5 12 10.5C13.1045 10.5 14 11.3955 14 12.5C14 13.4305 13.3615 14.206 12.5 14.429ZM12 11.5C11.448 11.5 11 11.948 11 12.5C11 13.052 11.448 13.5 12 13.5C12.552 13.5 13 13.052 13 12.5C13 11.948 12.552 11.5 12 11.5ZM4 2.5C2.8955 2.5 2 3.3955 2 4.5C2 5.231 2.3965 5.8635 2.9815 6.2125C2.791 6.485 2.615 6.767 2.4495 7.0565C1.584 6.5305 1 5.587 1 4.5C1 2.8435 2.3435 1.5 4 1.5C5.087 1.5 6.0305 2.0835 6.5565 2.9495C6.2665 3.115 5.9845 3.291 5.7125 3.4815C5.3635 2.896 4.7305 2.5 4 2.5Z"
        fill="#858585"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2669_111979"
          x1="12"
          y1="1.5"
          x2="12"
          y2="22.5004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DC69AE" />
          <stop offset="1" stop-color="#E95252" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ReminderIcon;
