import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: any;
}

const StyledIconFlag1 = styled.svg`
  .path {
    stroke: #aea9a1;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  &:hover .path {
    opacity: 1;
  }
`;

export const Flag = ({ className }: Props): JSX.Element => {
  return (
    <StyledIconFlag1
      className={`icon-flag-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 32 32"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18.356 20.16C17.4738 19.8345 16.6708 19.3255 16 18.6666C14.7538 17.4451 13.0783 16.7609 11.3333 16.7609C9.58831 16.7609 7.91286 17.4451 6.66666 18.6666M6.66666 18.6666V6.66664C7.91286 5.44513 9.58831 4.76093 11.3333 4.76093C13.0783 4.76093 14.7538 5.44513 16 6.66664C17.2462 7.88815 18.9216 8.57235 20.6667 8.57235C22.4117 8.57235 24.0871 7.88815 25.3333 6.66664V18M6.66666 18.6666V28M20 25.3333L22.6667 28L28 22.6666"
      />
    </StyledIconFlag1>
  );
};
