import { TextArea, Input } from "@bjsdistribution/dms-component-library";
import styled from "styled-components";
export const ReferenceContainer = styled.div`
  display: flex;
  height: 52px;
  width: 785px;
  padding: 10px 15px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
`;
export const InnerContainer = styled.div`
  display: flex;
  height: 52px;
  padding: 10px 15px;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
`;
export const MiniContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex: 1;
`;
export const StyledText = styled(TextArea)`
  display: flex;
  height: 96px;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 22px;
  resize: none;
`;
export const ReferInput = styled(Input)`
  border: none;
  border-radius: 15px;
  width: 330px;
`;
export const InputContainer = styled(Input)`
  border: none;
  border-radius: 15px;
  width: 330px;
`;
export const ReferInputContainer = styled(Input)`
  border: none;
  border-radius: 15px;
  width: 330px;
`;
export const StyledDateContainer = styled.div`
  position: relative;
  border-radius: 66px;
  border: 1px solid #e0e2e7;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  input[type="text"] {
    border-radius: 20px;
    background: #fff;
    border: none;
    color: #6a7378;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0;
    padding-left: 33px;
    &:focus-visible {
      outline: none;
    }
  }
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .react-datepicker-popper {
    z-index: 9999;
  }
`;

export const StyledDate = styled(Input)`
  border-radius: 66px;
  width: 175px;
  padding-right: 30px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  width: 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;
export const StyledTime = styled(Input)`
  border-radius: 30px;
  border: 1px solid #e0e2e7;
  background: #fff;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 8px 5px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
const Column = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Row = styled(Column)`
  gap: 10px;
`;
export const Right = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 15px;
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 15px;
  padding: 15px;
`;
export const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;
export const AssigneeContainer = styled.div`
  display: flex;
  padding: 15px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  border-radius: 15px;
  background: #f8fafc;
`;
export const DetailedContainer = styled.div`
  display: flex;
  width: 416px;
  padding: 15px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  border-radius: 66px;
  background: #f8fafc;
`;
export const ContentsContainer = styled(Column)`
  display: flex;
  margin: 15px;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  border-radius: 15px;
  background: #f8fafc;
  /* shadow/shadow-sm */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
`;

export const Label = styled.label`
  color: #758588;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const DatePickerWrap = styled.div`
  position: relative;

  border-radius: 66px;
  border: 1px solid #e0e2e7;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  input[type="text"] {
    border-radius: 20px;
    background: #fff;
    border: none;
    color: #6a7378;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 10px 0;
    padding-left: 33px;
    min-width: 18vw;
    &:focus-visible {
      outline: none;
    }
  }
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .react-datepicker-wrapper {
    background-color: white;
    border-radius: 66px;
  }
  .ant-picker-outlined {
    border-style: none;
    border-radius: 66px;
    outline: none;
    width: 100%;
  }
`;
