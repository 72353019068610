import React from "react";
import styled, { keyframes } from "styled-components";

interface LoaderProps {
  title?: string;
  subtitle?: string;
}

function Loader(props: LoaderProps) {
  return (
    <MainContainer>
      <Container>
        <Spinner />
        <Wrapper>
          <Title>{props.title || "Loading..."}</Title>
          <Subtitle>{props.subtitle || `Sit tight - won't be long!`}</Subtitle>
        </Wrapper>
      </Container>
    </MainContainer>
  );
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled components for the loading spinner
const Spinner = styled.div`
  border: 4px solid rgb(0 0 0 / 50%);
  border-left-color: #feba30;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${rotate} 1s linear infinite;
`;

const Container = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Subtitle = styled.span`
  color: white;
  font-size: 25px;
  text-transform: capitalize;
  font-weight: bold;
`;

const Title = styled.span`
  color: white;
  font-size: 25px;
  text-transform: capitalize;
  font-weight: bold;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Loader;
