import React from 'react';

export const Collapse = () => {
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58167 1.0184C4.6828 1.11965 4.7396 1.2569 4.7396 1.4C4.7396 1.5431 4.6828 1.68035 4.58167 1.7816L1.36327 5L4.58167 8.2184C4.63473 8.26784 4.67728 8.32745 4.70679 8.39369C4.73631 8.45993 4.75218 8.53144 4.75346 8.60394C4.75474 8.67645 4.7414 8.74847 4.71424 8.81571C4.68708 8.88295 4.64666 8.94403 4.59538 8.99531C4.5441 9.04658 4.48302 9.08701 4.41578 9.11417C4.34854 9.14132 4.27652 9.15466 4.20402 9.15338C4.13151 9.1521 4.06 9.13624 3.99376 9.10672C3.92753 9.07721 3.86791 9.03465 3.81847 8.9816L0.218472 5.3816C0.117348 5.28035 0.0605469 5.1431 0.0605469 5C0.0605469 4.8569 0.117348 4.71965 0.218472 4.6184L3.81847 1.0184C3.91972 0.917274 4.05697 0.860474 4.20007 0.860474C4.34317 0.860474 4.48042 0.917274 4.58167 1.0184Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
};
